import React, { ReactNode, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { spacing, breakpoints, themeLight } from '@naf/theme';
import { Text, TextVariant } from '@naf/text';
import { nafColor } from '@nafcore/theme';
import { DeleteOutline } from '@styled-icons/material/DeleteOutline';
import { Loader } from '@naf/loader';

interface Props {
  children?: ReactNode;
  onClick: (e: any) => void;
  text?: string;
  icon?: boolean;
  isLoading?: boolean;
  [rest: string]: any;
  className?: string;
}

export const ButtonLink = ({ isLoading = false, icon = false, text, className, onClick, ...rest }: Props) => {
  const [mouseDown, setMouseDown] = useState(false);

  const removeOutline = () => {
    setMouseDown(true);
  };

  const addOutline = (e: KeyboardEvent) => {
    if (e.key === 'Tab') {
      setMouseDown(false);
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', removeOutline);

    return () => window.removeEventListener('mousedown', removeOutline);
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', addOutline);

    return () => window.removeEventListener('keydown', addOutline);
  }, []);

  return (
    <Button
      className={className}
      id={mouseDown ? 'mousedown' : ''}
      onClick={isLoading ? null : onClick}
      isLoading={isLoading}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {isLoading ? (
        <LoadingButton>
          <Text variant={TextVariant.Button}>Laster...</Text>
          <Loader variant="neutral" size="small" />
        </LoadingButton>
      ) : (
        <>
          <Text variant={TextVariant.Button}>{text}</Text>
          {icon && <DeleteOutline size={16} />}
        </>
      )}
    </Button>
  );
};

export default ButtonLink;

const Button = styled.button<any>`
  /* reset style */
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  appearance: none;
  touch-action: manipulation;

  /* style */
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  color: ${nafColor.signature.black};
  margin-left: ${spacing.space24};
  justify-content: center;

  > svg {
    padding: 0 0 ${spacing.space8} ${spacing.space8};
  }

  > span {
    padding: 0 0 ${spacing.space8} 0;
    border-bottom: 2px solid;
    border-color: ${nafColor.primary.park};
  }

  &:hover {
    cursor: pointer;
    color: ${nafColor.primary.park};

    > span {
      border-color: transparent;
    }
  }

  @media (max-width: ${breakpoints.s}) {
    font-size: 1rem;
    line-height: 16px;
    padding: ${spacing.space24} 0 ${spacing.space4};
    margin-left: 0;
    display: flex;
    width: 100%;
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      border: none;
      color: ${({ theme }) => (theme.typography ? theme.typography.subtleText : themeLight.typography.subtleText)};

      &:visited {
        color: ${({ theme }) => (theme.typography ? theme.typography.subtleText : themeLight.typography.subtleText)};
      }

      &:hover {
        cursor: default;
      }

      &:hover,
      &:focus {
        color: ${({ theme }) => (theme.typography ? theme.typography.subtleText : themeLight.typography.subtleText)};
      }
    `}

  &#mousedown {
    outline: 0;
  }
`;

const LoadingButton = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin-left: ${spacing.space8};
  }
`;
